import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography, Paper} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { openLicenseTranzilaDialog, openLicenseDialog } from '../../actions/alarmSystemLicense';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom';

const LicenseTranzilaDialog = () => {
    const { user} = useUser();
    const { t, i18n } = useTranslation();   
    const loading = useSelector(state => state.global.loading);   
    const vat = useSelector(state => state.alarmSystemLicense.vat);
    const numberOfMonth = 1;//useSelector(state => state.alarmSystemLicense.freeMonths);
    const licenseTranzilaDialog = useSelector(state => state.alarmSystemLicense.licenseTranzilaDialog);
    const shekels = useSelector(state => state.alarmSystemLicense.monthlyShekelsAmmountPerSystem);
    const dollars = useSelector(state => state.alarmSystemLicense.monthlyDollarsAmmountPerSystem);
    const generalErrorText = useSelector(state => state.alarmSystemLicense.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];
    const price_from_action = useSelector(state => state.alarmSystemLicense.formData);
   
    let tranzilaLanguageForIframe;
    let tranzilaLangForInvoice;
    switch (language) {
        case "he":
            tranzilaLanguageForIframe = "il";
            tranzilaLangForInvoice = "heb";
            break;
        case "es":
            tranzilaLanguageForIframe = "es";
            tranzilaLangForInvoice = "eng";
            break;
        case "de":
            tranzilaLanguageForIframe = "de";
            tranzilaLangForInvoice = "eng";
            break;
        case "ru":
            tranzilaLanguageForIframe = "ru";
            tranzilaLangForInvoice = "eng";
            break;
        case "el":
        case "en":
        default:
            tranzilaLanguageForIframe = "us";
            tranzilaLangForInvoice = "eng";
            break;
    }
    const baseUrl = "https://direct.tranzila.com/pimaptm23/iframenew.php";//"https://direct.tranzila.com/ptm2023/iframenew.php";
    const location = window.location;
    const successUrl = `${location.origin}/api/payment/tranzilaSuccess`;//`82.166.20.233:44331/api/payment/tranzilaSuccess`;
    const failUrl = `${location.origin}/api/payment/tranzilaFail`;
    const notifyUrl = `${location.origin}/api/payment/tranzilaNotify`;//`${location.origin}/api/payment/tranzilaNotify`;
    const clientTransactionId = uuidv4();
    
    const price = price_from_action.TotalPriceToPay;
    var contact = `${user.firstName} ${user.lastName}`;
    if (user.company)
    {
        contact = `${user.company}`;
        if (user.businessNumber) {
            contact += ", " + `${user.businessNumber}`;
        }
    }
    
    const email = user.normalizedEmail;
  
    const a_currency = (((user.country == "PS") || (user.country == "IL")) ? 1 : 2);
    const a_pricePerSystem = price_from_action.PricePerPanel;//(after calculating month\year and shekel\dollar) (a_currency == 1) ? shekels : dollars;
    if (price_from_action.NumberOfSystems == 0)
        return null;
    var yearlybilling = "false";
    if (price_from_action.isChecked == true)
        yearlybilling = "true";
    var currentdate = moment().calendar();
    
    if (numberOfMonth)
    {
       currentdate = moment().add(numberOfMonth, 'months').calendar();
    }
   var tranzilaDat = moment(currentdate).format("YYYY-MM-DD");
 //  var tranzilaDat = moment().format("YYYY-MM-DD");

    
   /* const urlWithoutFreeMonth = `${baseUrl}?sum=${price}&currency=1&cred_type=1&u71=1&ilang=${tranzilaLanguageForIframe}` +
        `&success_url_address=${successUrl}&fail_url_address=${failUrl}&notify_url_address=${notifyUrl}` + `&contact=${encodeURIComponent(contact)}`+
        `& clientTransactionId=${clientTransactionId}` + `&UserID=${user.id}` +
        `&email=${encodeURIComponent(email)}` + `&NumberOfSystems=${price_from_action.NumberOfSystems}`+
        `&product_quantity=${price_from_action.NumberOfSystems}` + `&Ilang=${tranzilaLangForInvoice}`;*/
    const urlWithoutFreeMonth = `${baseUrl}?sum=1&currency=${a_currency}&cred_type=1&u71=1&ilang=${tranzilaLanguageForIframe}&lang=${tranzilaLanguageForIframe}` +
        `&success_url_address=${successUrl}&fail_url_address=${failUrl}&notify_url_address=${notifyUrl}` + `&contact=${encodeURIComponent(contact)}` +
        `& clientTransactionId=${clientTransactionId}` + `&UserID=${user.id}` + `&Phone=${user.phoneNumber}` +
        `&email=${encodeURIComponent(email)}` + `&pricePerUnit=${a_pricePerSystem}` + `&NumberOfSystems=${price_from_action.NumberOfSystems}` +
        `&product_quantity=${price_from_action.NumberOfSystems}` + `&pricePerMonth=${price_from_action.TotalPriceToPay}` + `&Ilang=${tranzilaLangForInvoice}` + `&additinalComment=${price_from_action.AdditionalInvoiceData}` + `&yearlyBilling=${yearlybilling}`;
    var urlForSendingToTranzila = urlWithoutFreeMonth
    if (numberOfMonth)
    {
        if (numberOfMonth > 0) 
            {
            urlForSendingToTranzila = urlWithoutFreeMonth + `&recur_start_date1=${tranzilaDat}` + /*`&recur_transaction=4_approved` +*/ `&tranmode=VK` + `&hidesum=1`;
            }
    }
      return (
          <Dialog
              aria-modal="true"
            disableBackdropClick={loading}
              open={licenseTranzilaDialog.isOpen}
             onClose={() => { Redirect("/home") }}
           

          >
            <DialogTitle> <Typography variant="h4" align="center">{t("PurchaseTitle")}</Typography></DialogTitle>
              <DialogContent
              aria-modal="true">
                {loading ? <LinearProgress style={{ height: 3 }} /> : <div style={{ height: 3 }} />}
                  {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}
                  <Typography variant="subtitle1">{(yearlybilling == "true" ? t("YearlyPrice") : t("MonthlyPrice")) + " " + `${price}` + " " + ((user.country == "IL" || user.country == "PS") ? t("SHEKEL") : t("DOLAR"))}</Typography>
                {numberOfMonth > 0 ? < Alert severity="info">{t("GetFree") + " " + `${numberOfMonth}` + " " + t("MonthsForFree")}</Alert> : null}
                  <Paper style={{ margin: 20, padding: 10 }}>
                      
                      <iframe width={440} height={540} style={{ border: 0 }} src={urlForSendingToTranzila}                          
                          sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"/>
                </Paper>
               
            </DialogContent>
            <DialogActions>
                      <a href="/home" color="black" style={{ textDecoration: 'none', fontWeight: "bold", width: 100, marginLeft: 10, marginRight: 10 }} >
                          {t("Close")}
                      </a>

              
            </DialogActions>
        </Dialog>
    );
}

export default LicenseTranzilaDialog;