import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useMediaQuery, ButtonGroup, Button,
    IconButton, TextField, Container, Breadcrumbs, Typography, Divider, Link,
    Stepper, MobileStepper, Step, StepLabel, FormControl, FormLabel, InputLabel, Select, FormHelperText,
    FormControlLabel, Switch, FormGroup, Grid, Chip, Tabs, Tab,
    Dialog, DialogTitle, DialogContent, DialogActions, Paper, Menu, MenuItem,
    List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, ListSubheader, Checkbox, AppBar, Toolbar
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { addPimaLicense, enableFreeLicense, openLicenseTranzilaDialog, getLicense, updateFormLicenseNumberOfSystems } from '../../actions/alarmSystemLicense';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import LicenseDialog from './LicenseDialog';
import LicenseTranzilaDialog from './LicenseTranzilaDialog';
import EditableTextField from '../common/EditableTextField';


const AlarmSystemLicense = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const {isPortalAdmin,user, token, unsetUser } = useUser();
    const history = useHistory();
    const loading = useSelector(state => state.global.loading);
    const license = useSelector(state => state.alarmSystemLicense.license);
    const generalErrorText = useSelector(state => state.alarmSystemLicense.generalErrorText);
    const succeddedText = useSelector(state => state.alarmSystemLicense.succeddedText);
    const formData = useSelector(state => state.alarmSystemLicense.formData);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const language = i18n.languages[0];
    const userId = user.id;
    var currEmailResponse;
    useEffect(() => {  
        dispatch(getLicense(userId, history, token, unsetUser, t, language, currEmailResponse));
    }, [dispatch, id, token]);
    var updatedNumberOfSystems;
    const numberOfSystemToAdd = formData.NumberOfSystems;
    const shekels = useSelector(state => state.alarmSystemLicense.monthlyShekelsAmmountPerSystem);
    const dollars = useSelector(state => state.alarmSystemLicense.monthlyDollarsAmmountPerSystem);
    const euros = useSelector(state => state.alarmSystemLicense.monthlyEurosAmmountPerSystem);
    const currntPriceToPaySystems = formData.TotalPriceToPay;
    const isFreeCountry = t(user.country);
    var currentClientGeneralComment = formData.AdditionalInvoiceData;
    const currIsChecked = formData.isChecked;
    const moreThan1ControlPanelConfirmation = formData.userApprovedMoreThen1;
    const alreadyBoughtConfirmation = formData.UserAprroveAlreadyBought;

    if (numberOfSystemToAdd == 0) {
        dispatch(updateFormLicenseNumberOfSystems(1, user.discountPrecent ? user.discountPrecent : 0, (user.country == "PS" || user.country == "IL") ? shekels : dollars, currentClientGeneralComment, currIsChecked, alreadyBoughtConfirmation, moreThan1ControlPanelConfirmation, t));
    
    }
    var generalNoteError = currentClientGeneralComment ? false : true;
    var enablePaymentButton = false;
    if (numberOfSystemToAdd > 0) {

        if (currentClientGeneralComment) {

            if (user.discountPrecent == 0) {//if this is end user
                if (user.numberOfSystems > 0) {
                    if (alreadyBoughtConfirmation)
                        enablePaymentButton = true;
                }
                else {//this is user's first purchase
                    enablePaymentButton = true;
                }

            }
            else {//installer
                if (numberOfSystemToAdd > 1) {
                    if (moreThan1ControlPanelConfirmation) {
                        enablePaymentButton = true;
                    }
                }
                else {//installer bought only 1 license
                    enablePaymentButton = true;
                }
            }

        }
        else
        {//general comment is null
            if (user.discountPrecent == 0) {//if this is end user
                if (user.numberOfSystems == 0)
                {
                    
                    enablePaymentButton = true;
                    generalNoteError = false;
                }
            }
        }
    }
    
   

    var trySuccess
    if (succeddedText)
     trySuccess = t(succeddedText);
    return (
        <Container>

            {true ? <LicenseDialog /> : null}
            {true ? <LicenseTranzilaDialog /> : null}
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />                        
            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{license ? license.NumberOfPaidSystems : generalErrorText}</Alert> : null}
            {succeddedText ? <Alert style={{ marginTop: 10 }} severity="success">{license ? license.NumberOfPaidSystems : trySuccess}</Alert> : null}
            {
                user.discountPrecent>0?
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <EditableTextField
                        disabled={false}
                        fullWidth
                        type="number"
                        error={null}
                        label={t("NumberOfPanelsToAdd")}
                        helperText={null}
                        value={numberOfSystemToAdd}
                            onChange={event => dispatch(updateFormLicenseNumberOfSystems(event.target.value, user.discountPrecent ? user.discountPrecent : 0, ((user.country == "PS") || (user.country == "IL")) ? shekels : dollars, currentClientGeneralComment, currIsChecked, alreadyBoughtConfirmation, moreThan1ControlPanelConfirmation, t))} />
                </div>:null
            }
            {numberOfSystemToAdd > 1 ?
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <FormControlLabel control=
                        {<Checkbox
                        label={t("MoreThan1SystemConfirmation")}
                            fullWidth
                        value={moreThan1ControlPanelConfirmation}
                            disabled={false}
                        checked={moreThan1ControlPanelConfirmation}
                        onChange={event => dispatch(updateFormLicenseNumberOfSystems(numberOfSystemToAdd, user.discountPrecent ? user.discountPrecent : 0, ((user.country == "PS") || (user.country == "IL")) ? shekels : dollars, currentClientGeneralComment, currIsChecked, alreadyBoughtConfirmation, event.target.checked ? true : false, t))} />} label={t("MoreThan1SystemConfirmation")} />
                </div> : null}
            <div style={{ marginTop: 10, maxWidth: 300 }}>
                <TextField readonly
                    disabled={false}
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    error={null}
                    label={(currIsChecked ? t("YearlyPrice") : t("MonthlyPrice")) + "(" + (((user.country == "PS") || (user.country == "IL")) ? t("SHEKEL") : t("DOLAR") )+ ")" }
                    helperText={null}
                    value={currntPriceToPaySystems} />
            </div>
            <div style={{ marginTop: 10, maxWidth: 300 }}>
                <TextField
                    disabled={false}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    type="text"
                    error={null}                    
                    label={generalNoteError ? t("GeneralCommentForInvoice")+"*": t("GeneralCommentForInvoice")}                   
                    error={generalNoteError}
                    helperText={null}
                    value={currentClientGeneralComment}
                    onChange={event => dispatch(updateFormLicenseNumberOfSystems(numberOfSystemToAdd, user.discountPrecent ? user.discountPrecent : 0, ((user.country == "PS") || (user.country == "IL")) ? shekels : dollars, event.target.value, currIsChecked, alreadyBoughtConfirmation, moreThan1ControlPanelConfirmation, t))} />
                                                


            </div>
            <div style={{ marginTop: 10, maxWidth: 300 }}>
                <FormControlLabel control=
                    {<Checkbox 
                        label={t("payForYear")}
                        fullWidth
                        value={currIsChecked}
                        disabled={false}
                        checked={currIsChecked}
                    onChange={event => dispatch(updateFormLicenseNumberOfSystems(numberOfSystemToAdd, user.discountPrecent ? user.discountPrecent : 0, ((user.country == "PS") || (user.country == "IL")) ? shekels : dollars, currentClientGeneralComment, event.target.checked ? true : false, alreadyBoughtConfirmation, moreThan1ControlPanelConfirmation, t))} />} label={t("payForYear")} />
            </div>
            <Alert style={{ marginTop: 10 }} severity="warning">{t("thePaymentStarsFromNextMnthRegardlessToPairing")}</Alert>

            {((user.numberOfSystems > 0) && (user.discountPrecent==0)) ? 
                <div style={{ marginTop: 10, maxWidth: 300 }}>
                    <FormControlLabel control=
                        {<Checkbox
                        label={t("AlreadyPurschased1licsence")}
                            fullWidth
                        value={alreadyBoughtConfirmation}
                            disabled={false}
                        checked={alreadyBoughtConfirmation}
                        onChange={event => dispatch(updateFormLicenseNumberOfSystems(numberOfSystemToAdd, user.discountPrecent ? user.discountPrecent : 0, ((user.country == "PS") || (user.country == "IL")) ? shekels : dollars, currentClientGeneralComment,currIsChecked, event.target.checked ? true : false,moreThan1ControlPanelConfirmation, t))} />} label={t("AlreadyPurschased1licsence")} />
                </div> : null}
            {isFreeCountry != "FREE" ?
                <div style={{ margin: 20 }}>
                    <Button variant="contained" disabled={loading || (!enablePaymentButton) } color="primary"
                    onClick={() => dispatch(openLicenseTranzilaDialog(true))}>{t("ContinueForCreditCard")}</Button>
            </div> :
                <div style={{ margin: 20 }}>
                    <Button variant="contained" disabled={loading || (!enablePaymentButton)} color="primary"
                        onClick={() => dispatch(enableFreeLicense(user.id, numberOfSystemToAdd, history, token, unsetUser, t, language, updatedNumberOfSystems))}>{t("ContinueForCreditCard")}</Button>
                </div>}
            {isPortalAdmin ?
                <div style={{ margin: 20 }}>
                    <Button variant="contained" disabled={loading} color="primary"
                        onClick={() => dispatch(addPimaLicense(currentClientGeneralComment, numberOfSystemToAdd, history, token, unsetUser, t, language, updatedNumberOfSystems))}>{t("PIMA_ONLY")}</Button>
                </div> : null}
            
        </Container>
    );
}

export default AlarmSystemLicense;

