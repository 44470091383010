import React, { useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, TextField, Container, List, ListItem, ListItemText, ListItemSecondaryAction,
    IconButton, Typography, Breadcrumbs, Divider, Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Edit as EditIcon } from '@material-ui/icons';
import { getAlarmSystems } from '../../actions/alarmSystems';
import { useTranslation } from 'react-i18next';
import useUser from '../../user/useUser';
import * as constants from '../../constants';

const AlarmSystems = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { isWebAccountUserSystem, token, unsetUser } = useUser();
    const loading = useSelector(state => state.global.loading);
    const alarmSystems = useSelector(state => state.alarmSystems.alarmSystems);
    const generalErrorText = useSelector(state => state.alarmSystems.generalErrorText);
    const dispatch = useDispatch();
    const language = i18n.languages[0];

    useEffect(() => {
        dispatch(getAlarmSystems(history, token, unsetUser, t, language));
    }, [dispatch, token]);

    return (
        <Container>

            <Breadcrumbs style={{ marginTop: 20 }}>
                <Typography color="textPrimary">{t("AlarmSystems")}</Typography>
            </Breadcrumbs>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            {generalErrorText ? <Alert style={{ marginTop: 10 }} severity="error">{generalErrorText}</Alert> : null}

            <div style={{ marginTop: 10 }}>
                <Paper variant="elevation" style={{ maxWidth: 400 }}>
                    {
                        alarmSystems && alarmSystems.length ?
                            <List component="nav">
                                {
                                    alarmSystems.map((a, i) => (
                                        <ListItem disabled={loading} key={i} button
                                            component={RouterLink} to={constants.ROUTE_ALARM_SYSTEMS_DETAILS__ID.replace(":id", a.id)}>
                                            <ListItemText primary={a.name} secondary={a.mac} />
                                            {/*<ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="edit">
                                                        <EditIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>*/}
                                        </ListItem>
                                    ))
                                }
                            </List> :
                            <Alert severity="warning">{t("NoAlarmSystemsToShow")}</Alert>
                    }
                </Paper>
            </div>
        </Container>

    );
}

export default AlarmSystems;